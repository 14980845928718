.header-border {
  border-left: 2px solid rgba(0, 0, 0, 0.2) !important;
  border-right: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.header-border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
}

.ds-tables .MuiOutlinedInput-input {
  text-align: center;
}

.header-border-print {
  border-left: 1px solid black !important;
  border-right: 1px solid black !important;
}

.header-border-bottom-print {
  border-bottom: 1px solid black !important;
}
/* datshseet sop video css */
.sopVideocontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sopcontainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
}
.Observedcontainer {
  width: 100%;
  text-align: left;
}
@media (min-width: 768px) {
  .sopVideocontainer {
    flex-direction: row;
  }  
  .sopcontainer {
    width: 48%;
  }  
  .Observedcontainer {
    width: 48%;
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .sopcontainer {
    width: 43%;
  }
  .Observedcontainer {
    width: 43%;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .Observedcontainer {
    text-align: center;
  }
}
