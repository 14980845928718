.valid {
    color: green;
}

.invalid {
    color: red;
}

.user-client-table {
    width: 100%;
    table-layout: fixed;
}

.user-actions {
    width: auto;
}

