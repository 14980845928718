* {
  font-family: Calibri, sans-serif;
}

.tableCss {
  text-align: center !important;
  border: 0.5px solid black !important;
  padding: 0px !important;
  background-color: white !important;
  font-size: 11px;
  min-width: 2rem;
}

.text-align-left {
  text-align: left !important;
  padding-left: 25px !important;
}

.tableCss2 {
  text-align: center !important;
  border: none !important;
  padding: 0px !important;
  background-color: white !important;
  font-size: 11px;
}

.standardUnitTable {
  width: -webkit-fill-available;
  font-size: 11px;
}
.sn {
  width: 1% !important;
}

.thead {
  width: 1% !important;
}

.content {
  font-family: Calibri, sans-serif;
}

.content td {
  /* padding: 5px; */
}
.img {
  height: 100% !important;
  width: 100% !important;
}
.hrStyle {
  margin-top: "4px";
  margin-bottom: "4px";
  padding-top: "0px";
  padding-bottom: "0px";
}

.break-remark {
  page-break-before: auto;
}

@media screen {
  #printOnly {
    display: none;
  }
  #screenOnly {
    display: block;
  }
}
@media print {
  #printOnly {
    display: block;
  }
  #screenOnly {
    display: none;
  }
}

/* customization to repeat table header */
table {
  /* page-break-inside: avoid !important; */
}

thead {
  display: table-header-group !important;
}

tbody {
  display: table-row-group !important;
}

/* Hide the original table header */
.no-print {
  display: none;
}

.flipedText {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  writing-mode: lr-tb;
  -webkit-text-stroke: thick;
  /* font-size: xxx-large; */
  padding: 0px;
  margin: 0px;
}

.labCertification {
  color: rebeccapurple;
  font-size: medium;
  -webkit-text-stroke-width: medium;
}
.certificateTitle {
  font-size: x-large;
  -webkit-text-stroke-width: medium;
}
.standardUnitTable td {
  text-align: center;
  padding: 5px;
}

.header-border {
  border-left: 1px solid rgba(0, 0, 0) !important;
  border-top: 1px solid rgba(0, 0, 0) !important;
  /* border-right: 2px solid rgba(0, 0, 0, 0.2) !important; */
}

.header-border-bottom-none {
  border-bottom: 0px solid rgba(0, 0, 0) !important;
}

.header-border-top-none {
  border-top: 0px solid rgba(0, 0, 0) !important;
}

.single-border-table-static-td td {
  border: none !important;
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;
}

.single-border-table-static-td td:last-child {
  border-right: 1px solid black !important;
}

.single-border-table-static-tr tr:last-child td {
  border-bottom: 1px solid black !important;
}

.history-table > td {
  padding: 5px;
}
