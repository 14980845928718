.srf-multivalue-table {
    border: 1px solid black;
    text-align: center;
    border-collapse: separate;
    border-spacing: 5px;
}

.srf-multivalue-table > tr > td {
    border: none;
}

.srf-multivalue-table > tr > th {
    border: none;
}