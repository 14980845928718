.font-link-footer {
  font-family: "Times New Roman", Times, serif;
  text-align: left;
  color: #000066;
  border: 1px solid black !important;
}

.single-border-table > tr > td:last-child, .single-border-table > tr > th:last-child {
  border-right: 1px solid black !important;
}

.single-border-table > tr:last-child td, .single-border-table > tr:last-child th {
  border-bottom: 1px solid black !important;
}

.single-border-table td.spacial, .single-border-table th.spacial {
  border-bottom: 1px solid black !important;
}

.single-border-table td.spacial2, .single-border-table th.spacial2 {
  border-bottom-width: 0px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.noBorder {
  border: none !important;
}

#pagbreaksborder {
  border-top: solid 1px #0000 !important;
  border-bottom: solid 1px #0000 !important;
  page-break-inside:avoid !important;
}